@import '@nerdwallet/base-styles/styles.scss';
@import '../../../styles/react-media-queries.scss';

.container {
  @include margin-bottom-5;
  @media #{$min-tablet} {
    max-width: calc(2 * 33%);
    @include margin-horizontal-auto;
  }

  @media #{$mobile-only} {
    @include padding-horizontal-3;
  }

  /* stylelint-disable-next-line max-nesting-depth */
  li:nth-child(1) {
    @media #{$mobile-only} {
      /* stylelint-disable-next-line property-disallowed-list */
      padding-top: 2rem;
      @include background-green-lightest;
    }
    /* stylelint-disable-next-line selector-max-class, max-nesting-depth */
    .descriptionBox {
      @include background-green-lightest;
    }
  }

  /* stylelint-disable-next-line max-nesting-depth */
  li:nth-child(2) {
    @media #{$mobile-only} {
      /* stylelint-disable-next-line property-disallowed-list */
      padding-top: 2rem;
      @include background-blue-lightest;
    }
    /* stylelint-disable-next-line selector-max-class, max-nesting-depth */
    .descriptionBox {
      @include background-blue-lightest;
    }
  }

  /* stylelint-disable-next-line max-nesting-depth */
  li:nth-child(3) {
    flex: auto;
    /* stylelint-disable-next-line property-disallowed-list, color-no-hex */
    background-color: #f4eee6;
    @include display-flex;
    @include flex-direction-row;
    @include align-items-center;
    @include margin-top-0;
    @include margin-bottom-0;
    /* stylelint-disable-next-line property-disallowed-list */
    padding: 2rem;
    /* stylelint-disable-next-line selector-max-class, max-nesting-depth */
    .descriptionBox {
      /* stylelint-disable-next-line property-disallowed-list */
      padding: 0 0 0 2rem;
      @include margin-all-0;
      @include align-items-center;
    }

    @media #{$mobile-only} {
      /* stylelint-disable-next-line property-disallowed-list */
      padding: 2rem;
      /* stylelint-disable-next-line property-disallowed-list, color-no-hex */
      background-color: #f4eee6;
      @include padding-horizontal-0;
      @include flex-direction-column;
      @include margin-top-3;
      /* stylelint-disable-next-line selector-max-class */
      .descriptionBox {
        @include display-flex;
        @include justify-content-center;
        @include align-items-flex-start;
        @include text-align-center;
        /* stylelint-disable-next-line property-disallowed-list */
        padding: 2rem;
        min-height: 14rem;
        /* stylelint-disable-next-line property-disallowed-list */
        margin-top: -6rem;
        /* stylelint-disable-next-line property-disallowed-list */
        padding-top: 7.5rem;
        flex: 1;
      }
    }
  }
}

@mixin shadow() {
  filter: drop-shadow(0px 1px 4px #64666a5c) drop-shadow(0px 4px 8px #64666a1a);
}

.title {
  @include margin-top-5;
  @include margin-bottom-4--wide;
  @include margin-bottom-3;
}

.cardContainer {
  @include display-flex;
  @include flex-direction-column;
  @include border-radius-2;
  /* stylelint-disable-next-line property-disallowed-list */
  margin: 1rem 0.5rem;
  flex: 1;
  @media #{$mobile-only} {
    flex: auto;
  }
}

.badgeContainer {
  @include display-flex;
  @include flex-direction-column;
  @include align-items-center;
}

.badgeCircle {
  @include display-flex;
  @include flex-direction-column;
  @include justify-content-center;
  @include align-items-center;
  @include shadow;
  @include position-relative;
  @include margin-horizontal-auto;
  z-index: 2;
  border-radius: 50%;
  height: 7.16rem;
  width: 7.16rem;
}

.badgeText {
  /* stylelint-disable-next-line property-disallowed-list */
  line-height: 1;
}

@mixin badgeImage() {
  @include position-absolute;
}

.topRight {
  @include badgeImage;
  top: -0.5rem;
  right: -3.5rem;
  /* stylelint-disable-next-line unit-disallowed-list */
  width: 60px;
  /* stylelint-disable-next-line unit-disallowed-list */
  height: 60px;
}

.bottom {
  @include badgeImage;
  bottom: -0.5rem;
  width: 8.3rem;
}

.descriptionBox {
  @include justify-content-center;
  @include align-items-flex-start;
  @include text-align-center;
  min-height: 14rem;
  /* stylelint-disable-next-line property-disallowed-list */
  padding: 2rem;
  /* stylelint-disable-next-line property-disallowed-list */
  margin-top: -6rem;
  /* stylelint-disable-next-line property-disallowed-list */
  padding-top: 7.5rem;
  flex: 1;
}

.descriptionBoxMobile {
  @media #{$mobile-only} {
    @include display-flex;
  }

  @include display-none;
}

.descriptionBoxDesktop {
  @media #{$mobile-only} {
    @include display-none //!important;
;
  }

  @include display-flex;
}

@mixin labelBox() {
  @include text-align-center;
  @include shadow;
  /* stylelint-disable-next-line property-disallowed-list */
  padding: 0 0.5rem;
  clip-path: polygon(3% 0, 100% 0, 100% 100%, 0% 100%);
}

.bottomLabelBox {
  @include labelBox;
  @include position-relative;
  /* stylelint-disable-next-line property-disallowed-list */
  padding: 0.4rem 1rem;
  z-index: 1;
  /* stylelint-disable-next-line property-disallowed-list */
  margin-top: -0.5rem;
  max-width: 14rem;
  min-width: 12rem;
}

.labelShadowWrapper {
  @include shadow;
}
